import { FC, PropsWithChildren } from 'react';

import { Button2Sizes, Button2Themes, Button2Type } from 'holded/modules/cms/domain/components/link';
import HeroIcon from 'holded/modules/cms/ui/shared/components/HeroIcon';
import NextImage from 'holded/modules/cms/ui/shared/components/Image';

const defaultSize = 'l';

const buttonSize: Record<Button2Sizes, string> = {
  s: 'px-[16px] py-[10px]',
  m: 'px-[32px] py-[14px]',
  l: 'px-[32px] py-[14px] md:px-[40px] md:py-[17px]',
  xl: 'px-[40px] py-[17px] md:px-[40px] md:py-[18px]',
  none: '',
};

const buttonTheme: Record<Button2Themes, string> = {
  primary:
    'justify-center rounded-md shadow-sm text-body3 text-white bg-blue-550 border border-blue-550 hover:opacity-80',
  transparent:
    'justify-center border border-blue-550 rounded-md shadow-sm text-body3 text-blue-550 bg-transparent hover:opacity-80',
  secondary:
    'justify-center rounded-md shadow-sm text-body3 text-blue-550 bg-white border border-white hover:opacity-80',
  link: 'justify-end gap-2 flex-1 text-blue-550',
};

const Button2: FC<PropsWithChildren<Button2Type>> = ({
  theme,
  className,
  children,
  icon,
  image,
  href,
  iconOptions,
  size,
  dataRef,
}) => {
  return (
    <a
      role="button"
      href={href}
      rel="noreferrer"
      {...(dataRef && { 'data-ref': dataRef })}
      className={`flex items-center ${iconOptions?.position == 'right' ? 'flex-row-reverse' : ''} ${
        buttonTheme[theme]
      } ${buttonSize[size ?? defaultSize]} ${className ?? ''}`}
    >
      {image && <NextImage media={image.data} classImg={'h-6 w-6 mr-2'} />}
      {icon && (
        <HeroIcon
          icon={icon}
          outline={iconOptions?.outline ?? true}
          size={iconOptions?.size ?? 6}
          extraClass={iconOptions?.class ?? ''}
        />
      )}
      {children}
    </a>
  );
};

export { Button2 };
